@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
  }

  #root {
    width: 100vw;
  }
  
  body {
    all: unset;
    font-family: "Montserrat", sans-serif;
    color: #014aac;
    display: flex;
  }
  
  body p {
    color: #000000;
    font-size: 16px;
  }
  
  h2 {
    font-size: 30px;
    text-align: center;
  }
  
  #navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: auto;
    width: 90%;
    height: 70px;
    background-color: #ffffff;
  }
  
  .active {
    background-color: #04aa6d;
  }
  
  .slider {
    width: 50%;
    height: 60vh;
    position: relative;
    overflow: hidden;
  }
  
  .slide {
    width: 100%;
    height: 70vh;
    position: absolute;
    transition: all 0.5s;
  }
  
  .carousel-img img {
    height: 100%;
    object-fit: contain;
  }
  
  .btn {
    position: absolute;
    width: 40px;
    height: 40px;
    padding: 10px;
    border: none;
    border-radius: 50%;
    z-index: 10;
    cursor: pointer;
    background-color: #fff;
    font-size: 18px;
  }
  .btn:active {
    transform: scale(1.1);
  }
  .btn-prev {
    top: 45%;
    left: 2%;
  }
  
  .btn-next {
    top: 45%;
    right: 2%;
  }
  
  .main_content {
    background-color: #FFFFFF;
  }
  
  #info-block {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #014aac;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 18px;
  }
  
  #info-block p {
    color: #FFFFFF;
  }
  
  #phone {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  #hours {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  #location {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  /* Font classes  */
  /* .titillium-web-extralight {
    font-family: "Titillium Web", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .titillium-web-light {
    font-family: "Titillium Web", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .titillium-web-regular {
    font-family: "Titillium Web", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .titillium-web-semibold {
    font-family: "Titillium Web", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .titillium-web-bold {
    font-family: "Titillium Web", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .titillium-web-black {
    font-family: "Titillium Web", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .titillium-web-extralight-italic {
    font-family: "Titillium Web", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
  
  .titillium-web-light-italic {
    font-family: "Titillium Web", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .titillium-web-regular-italic {
    font-family: "Titillium Web", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .titillium-web-semibold-italic {
    font-family: "Titillium Web", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  
  .titillium-web-bold-italic {
    font-family: "Titillium Web", sans-serif;
    font-weight: 700;
    font-style: italic;
  } */
  
  .form {
    width: 100%;
    height: 440px;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 0 20px -10px #000;
    padding: 20px 30px;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    position: relative;
  }
  .form h2 {
    margin: 10px 0;
    padding-bottom: 10px;
    width: 220px;
    color: #014AAC;
    border-bottom: 3px solid #014AAC;
  }
  .form input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: none;
    border: 0;
    font-family: "Montserrat", sans-serif;
    transition: all 0.3s;
    border-bottom: 2px solid #bebed2;
  }
  .form input:focus {
    border-bottom: 2px solid #78788c;
  }
  .form p:before {
    content: attr(type);
    display: block;
    margin: 28px 0 0;
    font-size: 14px;
    color: #5a5a5a;
  }
  .form button {
    float: right;
    padding: 8px 12px;
    margin: 8px 0 0;
    font-family: "Montserrat", sans-serif;
    border: 2px solid #78788c;
    border-radius: 6px;
    background: 0;
    color: #5a5a6e;
    cursor: pointer;
    transition: all 0.3s;
  }
  .form button:hover {
    border-radius: 6px;
    background: #012A53;
    border: 2px solid #FFFFFF;
    color: #fff;
  }
  .form div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: #014AAC;
    color: #fff;
    width: 100%;
    padding: 16px 4px 16px 0;
    border-radius: 6px;
    font-size: 13px;
    box-shadow: 10px 10px 40px -14px #000;
    margin-top: 100px;
    font-size: 14px;
  }
  
  .treatment-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .treatment-box {
    width: 40%;
    border-radius: 10px;
    box-shadow: 0 0 20px -10px #000;
  }
  
  .treatment-row {
    display: flex;
    width: 70%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .price-block {
    width: 60%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .price-box {
    display: flex; 
    flex-direction: row; 
    width: 90%; 
    justify-content: space-between;
    box-shadow: 0 0 13px -10px #000;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 6px;
  }
  
  .price-box-alt {
    color: #FFFFFF;
    background-color: #014AAC;
    display: flex; 
    flex-direction: row; 
    width: 90%; 
    justify-content: space-between;
    box-shadow: 0 0 13px -10px #000;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 6px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  footer {
    color: white;
  }

  footer p {
    color: white;
  }
  
  .lower-footer {
    width: 100%;
    background-color: #012A53;
    color: #FFFFFF;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;   
  }
  
  .lower-footer a {
    color: #FFFFFF;
    text-decoration: none;
  }
  
  .lower-footer p {
    color: #FFFFFF;
    text-decoration: none;
  }
  
  .contact-block {
    margin-bottom: 100px;
    margin-top: 50px;
    width: 80%;
    display: flex;
    justify-content: center;
  }
  
  /* Burger Styles */

  /* Position and sizing of burger button */
.bm-burger-button {
    position: relative;
    width: 36px;
    height: 30px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: black;
    position: absolute;
  }